<template>
  <div
    class="widgetContainer bg-white widgetContainer--scrollable domesticWire">
    <div class="widgetContainer__header-fixed" v-if="paymentStatus == ''">
      <div class="navigationHeader">
        <div class="navigationHeader__inner">
          <span class="icon-Arrow-big-left cancel" @click="cancel" />
          <p class="title">
            {{ $t('payment_domesticwire_title') }}
          </p>
          <span class="el-icon-close" @click="drawerClose" />   
        </div>
      </div>
    </div>
    <div class="widgetContainer__body">
      <div class="domesticWire__details">
        <el-form class="wise-form" :model="formData" :rules="formRules">
          <el-form-item class="is-no-asterisk" prop="name" :label="$t('Name')">
            <el-input :value="getSelectedContactData.name" />
          </el-form-item>

          <div class="el-input--full-width el-input">
            <el-form-item
              prop="address.line1"
              :rules="formRules.line1"
              class="is-no-asterisk"
              :label="$t('address_1')">
              <el-input style="display: none" />
              <el-input
                v-model="formData.address.line1"
                style="display: none" />
              <div class="el-input">
                <gmap-autocomplete
                  :placeholder="$t('address_1')"
                  autocomplete="disabled"
                  :value="formData.address.line1"
                  :options="{
                    componentRestrictions: { country: country }
                  }"
                  @change.native="formData.address.line1 = $event.target.value"
                  @place_changed="updateAddress($event)"
                  :select-first-on-enter="true"
                  class="el-input__inner" />
              </div>
            </el-form-item>
          </div>

          <el-form-item prop="address.line2" :label="$t('address_2')">
            <el-input
              :placeholder="$t('address_2')"
              v-model="formData.address.line2" />
          </el-form-item>
          <div class="wise-form__inline">
            <el-form-item
              prop="address.city"
              class="is-no-asterisk"
              :rules="formRules.city"
              :label="$t('address_City')">
              <el-input
                style="flex: 2"
                :placeholder="$t('address_City')"
                v-model="formData.address.city" />
            </el-form-item>
            <el-form-item
              prop="address.country"
              class="is-no-asterisk"
              :rules="formRules.state"
              :label="$t('address_State')">
              <el-input
                style="flex: 1"
                :placeholder="$t('address_State')"
                v-model="formData.address.state" />
            </el-form-item>
            <el-form-item
              prop="address.postalCode"
              class="is-no-asterisk"
              :rules="formRules.postalCode"
              :label="$t('address_Zipcode')">
              <el-input
                style="flex: 1"
                :placeholder="$t('address_Zipcode')"
                v-model="formData.address.postalCode" />
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
    <div class="el-drawer__footer">
      <el-button
        type="primary"
        :disabled="!isFormValid"
        @click="next()"
        class="width-100 el-button__brand brand">
        {{ $t('next') }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { formatGoogleMapData } from '@/utils/gmap';
export default {
  created() {
    if (!this.getPaymentType) {
      this.$router.push('/pay/paymentType');
    }

    /** @type {import('@/modules/contact/store/state').contactState['createContactData']} */
    const contact = this.getSelectedContactData;

    const payDetails = this.getPayDetails;

    let address = null;
    if (payDetails && payDetails.address) {
      address = payDetails.address;
    } else if (
      contact &&
      contact.id &&
      contact.wire &&
      contact.wire.domestic &&
      contact.wire.domestic.address
    ) {
      address = contact.wire.domestic.address;
    }

    if (address) {
      const { line1, line2, city, postalCode, state } = address;
      this.formData.address = {
        line1,
        line2,
        city,
        postalCode,
        country: this.country,
        addressType: 'legal',
        state
      };
    }
  },
  computed: {
    ...mapGetters('pay', ['getPaymentType', 'getPayDetails']),
    ...mapGetters('account', ['getSelectedAccount']),
    ...mapGetters('contact', ['getSelectedContactData']),
    ...mapGetters('user', ['getPersonDetails']),
    isFormValid() {
      return (
        this.formData.address.line1 &&
        this.formData.address.city &&
        this.formData.address.postalCode
      );
    },
    country() {
      return 'US';
    }
  },
  data() {
    return {
      formData: {
        amount: '0',
        description: '',
        address: {
          line1: null,
          line2: null,
          city: null,
          postalCode: null,
          country: 'US',
          addressType: 'legal',
          state: null
        }
      },
      contactChange: false,
      paymentStatus: '',
      paymentInfo: '',
      formRules: {
        line1: [
          {
            required: true,
            message: 'Address 1 is required'
          }
        ],
        city: [
          {
            required: true,
            message: 'City is required'
          }
        ],
        state: [
          {
            required: true,
            message: 'State is required'
          }
        ],
        postalCode: [
          {
            required: true,
            message: 'Zip Code is required'
          }
        ]
      }
    };
  },
  methods: {
    ...mapActions('pay', ['intrabankPay']),
    ...mapActions('account', ['getAccount']),
    ...mapMutations('pay', ['setPayDetails']),
    ...mapActions('contact', ['updateContact']),
    cancel() {
      this.drawerBack();
    },
    updateAddress(e) {
      const address = formatGoogleMapData(e);
      this.formData.address = address;
      this.contactChange = true;
    },
    next() {
      if (this.isFormValid) {
        try {
          if (
            this.contactChange === false &&
            this.getSelectedContactData &&
            this.getSelectedContactData.wire &&
            this.getSelectedContactData.wire.domestic.address &&
            (this.getSelectedContactData.wire.domestic.address.line1 !==
              this.formData.address.line1 ||
              this.getSelectedContactData.wire.domestic.address.line2 !==
                this.formData.address.line2 ||
              this.getSelectedContactData.wire.domestic.address.city !==
                this.formData.address.city ||
              this.getSelectedContactData.wire.domestic.address.postalCode !==
                this.formData.address.postalCode ||
              this.getSelectedContactData.wire.domestic.address.country !==
                this.formData.address.country ||
              this.getSelectedContactData.wire.domestic.address.state !==
                this.formData.address.state)
          ) {
            this.contactChange = true;
          }

          const { line1, line2, city, postalCode, state } =
            this.formData.address;
          const country = this.country;
          this.setPayDetails({
            address: {
              line1,
              line2,
              city,
              postalCode,
              country,
              state
            },
            contactChange: this.contactChange
          });

          this.drawerPush('domestic-wire-account');
        } catch (e) {
          console.log(e);
          this.apiErrorMessage(e);
        }
      }
    },
    done() {
      this.goToDashboard();
    },
    shallowEqual(object1, object2) {
      const keys1 = Object.keys(object1);
      const keys2 = Object.keys(object2);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (let key of keys1) {
        if (object1[key] !== object2[key]) {
          return false;
        }
      }

      return true;
    }
  }
};
</script>
<style lang="scss">
.domesticWire {
  &--completed {
    padding-top: 65px;

    .payment-done-header {
      text-align: center;
      padding-bottom: 48px;
    }

    .icon {
      font-size: 75px;
      margin-bottom: 36px;
      display: inline-block;

      .path1 {
        &:before {
          color: var(--color-green);
        }
      }
    }

    .title {
      font-size: 24px;
      line-height: 30px;
      padding-bottom: 16px;
    }

    .description {
      color: rgba(#3c3c43, 0.6);
      font-size: 14px;
      line-height: 20px;
    }

    .el-button {
      margin-top: auto;
    }
  }

  .widgetContainer__header-fixed {
    padding: 0;
  }

  .welcome__illustration {
    margin: 10% auto;
  }

  .header-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 41px;
    padding-bottom: 6px;
    margin-bottom: 10px;
  }

  .widgetContainer__body {
    position: relative;
  }

  &__header {
    font-size: 20px;
    font-weight: 600;
    line-height: 41px;
    padding: 6px 0;
  }

  &__details {
    .header-1 {
      text-transform: capitalize;
    }

    .sub-header {
      color: rgba(#3c3c43, 0.6);
      font-size: 13px;
      padding-bottom: 7px;
      text-transform: uppercase;
    }
  }

  .purpose-input {
    outline: none;
    border: none;
    text-align: right;
    input {
      text-align: right;
      font-weight: bold;
      color: #000000;
    }
  }
  .success-desc {
    font-size: 17px;
    color: #3c3c43;
    margin-bottom: 25px;
  }

  &__done-btn {
    width: 100%;
    &.is-disabled {
      opacity: 0.5;
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 16px;
    border-top: 1px solid var(--branding-sidebar-border);;
    button {
      width: 100%;
    }
  }
}
</style>
